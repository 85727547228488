import { get, isEmpty, omit, set, uniqBy } from "lodash";

import { Fields, ProductEntity } from "~/constants/forms";
import { MAXIMUM_NO_OF_ARRAY_ELEMENT } from "~/constants/numbers";
import { DPD_DIRECT_PARCEL_NETWORKS } from "~/constants/services";
import { roundToDecimal } from "~/utils/number";
import { getValue, omitNilValues } from "~/utils/object";
import { ServiceModels } from "~/models";
import { every } from "lodash/collection";

export const calculateProductTotalValue = ({ productQty, unitValue } = {}) => {
  const quantity = parseInt(productQty, 10) || 0;
  const value = parseFloat(unitValue, 10) || 0;

  return quantity * value;
};

export const calculateProductTotalWeight = ({
  productQty,
  unitWeight,
} = {}) => {
  const quantity = parseInt(productQty, 10) || 0;
  const weight = parseFloat(unitWeight, 10) || 0;

  return quantity * weight;
};

export const calculateParcelsTotal = (parcels, calcFn) =>
  parcels.reduce((acc, parcel) => {
    const products = get(parcel, "products", []);

    products.forEach(product => {
      acc += parseFloat(calcFn(product));
    });

    return acc;
  }, 0);

export const calculateParcelsTotalValues = parcels =>
  calculateParcelsTotal(parcels, calculateProductTotalValue);

export const calculateParcelsTotalWeight = parcels =>
  calculateParcelsTotal(parcels, calculateProductTotalWeight);

export const getParcelsDataErrors = (
  parcels,
  shippingWeight,
  numberOfParcels
) => {
  const errors = [];
  if (parseInt(numberOfParcels || 0) > MAXIMUM_NO_OF_ARRAY_ELEMENT) {
    errors.push(
      `Total Number of Packages cannot exceed ${MAXIMUM_NO_OF_ARRAY_ELEMENT}`
    );
  }

  if (shippingWeight && calculateParcelsTotalWeight(parcels) > shippingWeight) {
    errors.push(
      "Total Weight of all products cannot exceed the Shipment Weight"
    );
  }

  if (calculateParcelsTotalValues(parcels) > 99999999.99) {
    errors.push("Total Value of all products cannot exceed 99999999.99");
  }

  return errors;
};

export const getNumberOfProductsErrors = (numberOfParcels, parcels) => {
  const errors = [];

  Array(parseInt(numberOfParcels || 0))
    .fill()
    .forEach((v, i) => {
      if (!get(parcels, `[${i}].products.length`)) {
        errors.push(`No product details entered for Package ${i + 1}`);
      }
    });

  return errors;
};

export const prepareParcelProductsValues = (
  parcels,
  selectedOutboundNetwork
) => {
  const isProductDescription = ServiceModels.isFlagDescription(
    selectedOutboundNetwork,
    "prodRequired"
  );

  return parcels.map(parcel => {
    const products = getValue(parcel, "products", []);
    const updatedProducts = products.map(product => {
      const unitWeight = roundToDecimal(
        get(product, ProductEntity.UNIT_WEIGHT)
      );
      const unitValue = roundToDecimal(get(product, ProductEntity.UNIT_VALUE));

      // NOTE: must omitNilValues for unitWeight, unitValue because "@@redux-form/REINITIALIZE" set undefined as empty string
      // productSchema allow empty string and as result we've got an incorrect validation
      return omit(
        omitNilValues({
          ...product,
          ...(!isProductDescription && { unitWeight, unitValue }),
        }),
        ["productId"]
      );
    });

    return {
      ...parcel,
      products: updatedProducts,
    };
  });
};

export const setupInitialParcels = data => {
  const { shipment, selectedOutboundNetwork } = data;
  const outboundConsignment = shipment.outboundConsignment;
  const { parcels } = outboundConsignment;

  if (
    every(parcels?.[0]?.products, product => isEmpty(omitNilValues(product)))
  ) {
    return {
      ...data,
      shipment: omit(shipment, ["outboundConsignment.parcels"]),
    };
  }

  const mappedParcels = parcels.map(({ products }) => ({
    products,
  }));

  set(
    outboundConsignment,
    Fields.PARCELS,
    prepareParcelProductsValues(mappedParcels, selectedOutboundNetwork)
  );

  return {
    ...data,
    shipment: {
      ...shipment,
      outboundConsignment,
    },
  };
};

export const setupInitialParcelsWithIds = data => {
  const { shipment, selectedOutboundNetwork } = data;
  const outboundConsignment = shipment.outboundConsignment;
  const { parcels, networkCode } = outboundConsignment;

  if (
    isEmpty(parcels?.[0]?.products) &&
    (!shipment.generateCustomsData ||
      !DPD_DIRECT_PARCEL_NETWORKS.includes(networkCode))
  ) {
    return {
      ...data,
      shipment: omit(shipment, ["outboundConsignment.parcels"]),
    };
  }

  const mappedParcels = parcels.map(({ products, parcelId }) => ({
    products,
    parcelId,
  }));

  set(
    outboundConsignment,
    Fields.PARCELS,
    prepareParcelProductsValues(mappedParcels, selectedOutboundNetwork)
  );

  return {
    ...data,
    shipment: {
      ...shipment,
      outboundConsignment,
    },
  };
};

export const countProductPackages = products =>
  uniqBy(products, "packageNumber")?.length || 0;
