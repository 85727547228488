import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import {
  arrayPush,
  arrayRemove,
  arraySplice,
  autofill,
  change,
  destroy,
  resetSection,
  SubmissionError,
  touch,
  untouch,
} from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";
import { createPayloadAction } from "@dpdgroupuk/redux-action-creator/lib/actionTypes";

import { addressBookApi, labelsApi, shipmentApi } from "~/apis";
import {
  ADDRESS,
  CONTACT_DETAILS,
  DELIVERY_CONTACT,
  DELIVERY_DETAILS_SEARCH_FORM,
  EDIT_IMPORTER_FORM,
  EXPORTER_DETAILS,
  ExporterDetailsEntity,
  Fields,
  FilterFields,
  IMPORTER_DETAILS,
  ImporterDetailsEntity,
  INBOUND_CONSIGNMENT,
  INVOICE,
  InvoiceEntity,
  PRODUCT_DESCRIPTIONS_FORM,
  ProductDescriptionsEntity,
  RETURN_DETAILS_SEARCH_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import {
  AddressModels,
  CustomsModels,
  InvoiceModels,
  LiabilityModels,
  NewShipmentModels,
  ServiceModels,
  SettingsModels,
  ShipmentModels,
} from "~/models";
import {
  AddressBookActions,
  ProfilesSelectors,
  ReferenceActions,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";
import { getDeepKeys, getValue, toUppercaseValues } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";
import { getProductDescription } from "~/components/ProductDescriptionsContent/selectors";
import * as ShipmentSelectors from "./selectors";
import { cloneDeep, pick } from "lodash";
import { DEFAULT_ZERO_VALUE } from "~/constants/strings";

export const ActionTypes = createActionTypes("SHIPMENT", {
  CREATE_SHIPMENT: createAsyncActionTypes("CREATE_SHIPMENT"),
  UPDATE_SHIPMENT: createAsyncActionTypes("UPDATE_SHIPMENT"),
  FETCH_BY_ID: createAsyncActionTypes("FETCH_BY_ID"),
  GET_LABEL: createAsyncActionTypes("GET_LABEL"),
  GET_LABELS_BY_SHIPMENT_IDS: createAsyncActionTypes(
    "GET_LABELS_BY_SHIPMENT_IDS"
  ),
  GET_INVOICE_LABEL: createAsyncActionTypes("GET_INVOICE_LABEL"),
  GET_UNPRINTED_LABELS: createAsyncActionTypes("GET_UNPRINTED_LABELS"),
  GENERATE_UNIQUE_REFERENCE_1: createAsyncActionTypes(
    "GENERATE_UNIQUE_REFERENCE_1"
  ),
  VALIDATE_UNIQUE_REFERENCE_1: createAsyncActionTypes(
    "VALIDATE_UNIQUE_REFERENCE_1"
  ),
  SET_SELECTED_ADDRESS_BOOK: "SET_SELECTED_ADDRESS_BOOK",
  SET_SELECTED_RETURN_ADDRESS_BOOK: "SET_SELECTED_RETURN_ADDRESS_BOOK",
  CREATE_UPDATE: createAsyncActionTypes("CREATE_UPDATE"),
  CLEAR_SELECTED_RETURN_ADDRESSBOOK: "CLEAR_SELECTED_RETURN_ADDRESSBOOK",
  CLEAR_SELECTED_ADDRESSBOOK: "CLEAR_SELECTED_ADDRESSBOOK",
  CLEAR_PAGE: "CLEAR_PAGE",
});

export const clearContactDetails = formName => dispatch =>
  dispatch(resetSection(formName, DELIVERY_CONTACT));

export const resetInboundConsignment = formName => dispatch =>
  dispatch(resetSection(formName, INBOUND_CONSIGNMENT));

export const clearReturnItemDescription = formName => dispatch =>
  dispatch(
    autofill(formName, ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION)
  );

export const updateReturnShortName = shortName => dispatch =>
  dispatch(
    autofill(
      RETURN_DETAILS_SEARCH_FORM,
      FilterFields.SEARCH_CRITERIA_VALUE,
      shortName
    )
  );

export const touchShipmentFormFields =
  (formName, fields, formValues) => dispatch => {
    fields.forEach(
      field =>
        isEmpty(get(formValues, field)) && dispatch(touch(formName, field))
    );
  };

export const initializeShippingInformation =
  pageConfig => (dispatch, getState) => {
    const data = ShipmentSelectors.getInitialInvoiceData(
      getState(),
      pageConfig
    );

    return dispatch(initializeForm(pageConfig.formName, data));
  };

export const createShipment = createAsyncAction(
  pageConfig => (dispatch, getState) => {
    const state = getState();
    const query = ShipmentSelectors.getShipmentQuery(state, pageConfig);
    const selectedCountry = ShipmentSelectors.getSelectedCountry(
      state,
      pageConfig
    );
    const isSwapItOrReverseIt =
      ShipmentSelectors.isSwapItOrReverseItShipmentType(state, pageConfig);
    const isDomestic = AddressModels.isCountryDomestic(
      selectedCountry.countryKey
    );

    if (isSwapItOrReverseIt) {
      return shipmentApi
        .createShipmentCollection(query)
        .then(({ data }) => data);
    }

    if (isDomestic) {
      return shipmentApi.createShipmentDomestic(query).then(({ data }) => data);
    }

    return shipmentApi
      .createShipmentInternational(query)
      .then(({ data }) => data);
  },
  ActionTypes.CREATE_SHIPMENT
);

// @see  https://it.dpduk.live/version/customer-shipping/sprint-1.18/diag_fGPa5D6FYFxijSYL.html
export const updateShipment = createAsyncAction(
  (pageConfig, shipmentId, isScanned = false) =>
    (dispatch, getState) => {
      const state = getState();
      const query = omit(
        ShipmentSelectors.getShipmentQuery(state, pageConfig),
        ["outboundConsignment.collectionDetails"]
      );

      // NOTE: passed isScanned it only from Scan page (allow edit ref2/ref3 fields)
      if (isScanned) {
        query.isScanned = isScanned;
      }

      const selectedCountry = ShipmentSelectors.getSelectedCountry(
        state,
        pageConfig
      );
      const isSwapItOrReverseIt =
        ShipmentSelectors.isSwapItOrReverseItShipmentType(state, pageConfig);
      const isDomestic = AddressModels.isCountryDomestic(
        selectedCountry.countryKey
      );

      if (isSwapItOrReverseIt) {
        return shipmentApi
          .updateShipmentCollection(shipmentId, query)
          .then(({ data }) => data);
      }

      if (isDomestic) {
        return shipmentApi
          .updateShipmentDomestic(shipmentId, query)
          .then(({ data }) => data);
      }

      return shipmentApi
        .updateShipmentInternational(shipmentId, query)
        .then(({ data }) => data);
    },
  ActionTypes.UPDATE_SHIPMENT
);

export const fetchLabel = createAsyncAction(
  (shipmentId, query) =>
    shipmentApi
      .getLabel(shipmentId, query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_LABEL
);

export const fetchLabels = createAsyncAction(
  query =>
    labelsApi
      .getLabelsByShipmentIds(query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_LABELS_BY_SHIPMENT_IDS
);

export const fetchInvoiceLabel = createAsyncAction(
  (shipmentId, query) =>
    shipmentApi
      .getInvoiceLabel(shipmentId, query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_INVOICE_LABEL
);

export const fetchUnprintedLabels = createAsyncAction(
  query =>
    labelsApi
      .getUnprintedLabels(query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_UNPRINTED_LABELS
);

export const generateUniqueReference1 = createAsyncAction(
  () =>
    shipmentApi
      .generateUniqueReference1()
      .then(({ data: { reference } }) => reference),
  ActionTypes.GENERATE_UNIQUE_REFERENCE_1
);

export const validateUniqueReference1 = createAsyncAction(
  (customerRef, options) =>
    shipmentApi
      .validateUniqueReference1(customerRef, options)
      .then(({ data }) => data),
  ActionTypes.VALIDATE_UNIQUE_REFERENCE_1
);

export const setSelectedAddressBook = addressBook =>
  createPayloadAction(ActionTypes.SET_SELECTED_ADDRESS_BOOK, addressBook);

export const setSelectedReturnAddressBook = addressBook =>
  createPayloadAction(
    ActionTypes.SET_SELECTED_RETURN_ADDRESS_BOOK,
    addressBook
  );

export const handleSubmitShipment =
  ({ pageConfig, notifier }) =>
  (dispatch, getState) =>
  onSuccess => {
    const state = getState();

    if (ShipmentSelectors.isEditProductFormActive(state)) {
      throw new SubmissionError({
        [ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS]: Fields.PARCELS,
      });
    }

    const isLoadingOutboundServices =
      ShipmentSelectors.isLoadingOutboundServices(state, pageConfig);
    const isLoadingInboundServices = ShipmentSelectors.isLoadingInboundServices(
      state,
      pageConfig
    );
    const disableButton = ShipmentSelectors.shouldDisablePrintButtons(
      state,
      pageConfig
    );

    if (isLoadingOutboundServices || isLoadingInboundServices) return;

    if (!disableButton) {
      return onSuccess();
    }

    // Test for form errors at the page, display and scroll if any
    const errors = ShipmentSelectors.getShipmentFormSyncErrors(
      state,
      pageConfig
    );

    const flat = getDeepKeys(errors);
    dispatch(touch(pageConfig.formName, ...flat));
    const isErrorVisible = notifier.scrollToError(flat);

    if (isErrorVisible) return;

    // Otherwise, look for hidden errors (Parcel, Invoice) at the page
    const isValidInvoiceSection = ShipmentModels.isValidInvoiceSection(errors);
    const isOnlyInvoiceImporterSectionInvalid =
      ShipmentModels.isOnlyInvoiceImporterSectionInvalid(errors);
    const isParcelsDataValid = ShipmentSelectors.isParcelsDataValid(
      state,
      pageConfig
    );

    if (!isValidInvoiceSection || !isParcelsDataValid) {
      const invalidSection = isOnlyInvoiceImporterSectionInvalid
        ? IMPORTER_DETAILS
        : EXPORTER_DETAILS;
      const invoiceErrorField = `${INVOICE}.${invalidSection}`;

      const error = isParcelsDataValid
        ? { invoiceErrorField }
        : { [ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS]: Fields.PARCELS };

      throw new SubmissionError(error);
    } else {
      return onSuccess();
    }
  };

export const handleSubmitAddressbook =
  ({ pageConfig, notifier }) =>
  (dispatch, getState) =>
  onSuccess => {
    const errors = ShipmentSelectors.getAddressBookErrors(
      getState(),
      pageConfig
    );

    if (!isEmpty(errors)) {
      const flat = getDeepKeys(errors);
      dispatch(touch(pageConfig.formName, ...flat));
      notifier.scrollToError(flat);
    } else {
      onSuccess();
    }
  };

export const createOrUpdateAddressBook = createAsyncAction(
  (addressbookId, body, abortController) => async () => {
    const updateObject = {
      addressBookType: body.addressBookType,
    };

    let result;

    if (addressbookId) {
      result = await addressBookApi.updateAddressBook(
        addressbookId,
        updateObject,
        body,
        {
          signal: abortController.signal,
        }
      );
    } else {
      result = await addressBookApi.insertAddressBook(body, {
        signal: abortController.signal,
      });
    }

    const { data } = await addressBookApi.fetchAddressBookById(
      result.data.addressbookId,
      updateObject,
      {
        signal: abortController.signal,
      }
    );

    return data;
  },
  ActionTypes.CREATE_UPDATE
);

export const clearAddressBook = form => ({
  type:
    form === DELIVERY_DETAILS_SEARCH_FORM
      ? ActionTypes.CLEAR_SELECTED_ADDRESSBOOK
      : ActionTypes.CLEAR_SELECTED_RETURN_ADDRESSBOOK,
});

export const clearCustoms = formName => dispatch =>
  dispatch(
    resetSection(
      formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY
    )
  );

export const setDefaultCurrency = pageConfig => (dispatch, getState) => {
  const userCurrency = CustomsModels.getDefaultCurrency(
    UmsSelectors.getPreferences(getState()),
    ProfilesSelectors.getActiveProfile(getState(), pageConfig)
  );
  dispatch(
    autofill(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY,
      userCurrency
    )
  );
};

// @see: comments from https://geopost.jira.com/browse/CSHIP-6395
export const setDefaultDeliveryDescription =
  (pageConfig, allowedFields) => (dispatch, getState) => {
    const state = getState();
    const isDeliveryDescriptionVisible =
      allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION];
    const userDescription = getValue(
      UmsSelectors.getPreferences(getState()),
      "shippingDefaults.international.intContentDescription",
      ""
    ).toUpperCase();
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );

    if (
      isDeliveryDescriptionVisible &&
      userDescription &&
      !getValue(
        createShipmentValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
        ""
      )
    ) {
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
          userDescription
        )
      );
    }
  };

export const resetShippingInformation = pageConfig => dispatch => {
  dispatch(resetSection(pageConfig.formName, INVOICE));
  dispatch(initializeShippingInformation(pageConfig));
};

export const deleteParcelProduct = (formName, selectedProduct) => dispatch => {
  if (selectedProduct.productIndex !== undefined) {
    dispatch(
      arrayRemove(
        formName,
        `outboundConsignment.parcels[${selectedProduct.packageNumber}].products`,
        selectedProduct.productIndex
      )
    );
  }
};

export const clearShipment = () => ({
  type: ActionTypes.CLEAR_PAGE,
});

export const changeCountry = (pageConfig, path, country) => dispatch => {
  dispatch(autofill(pageConfig.formName, path, country));
};

export const setupDefaultExtendedLiability =
  pageConfig => (dispatch, getState) => {
    const preferences = UmsSelectors.getPreferences(getState());
    const formValues = ShipmentSelectors.getShipmentFormValues(
      getState(),
      pageConfig
    );
    const countryCode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    );
    const liability = LiabilityModels.getDefaultLiability(
      preferences,
      countryCode
    );
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
        liability
      )
    );
    liability &&
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
          LiabilityModels.getDefaultLiabilityValue(preferences, countryCode)
        )
      );
  };

export const setupDefaultAdditionalDataRequirements =
  pageConfig => (dispatch, getState) => {
    const { destinationTaxId, gstVatPaid } = ShipmentModels.getInitialTaxValues(
      ProfilesSelectors.getActiveProfile(getState(), pageConfig),
      UmsSelectors.getPreferences(getState())
    );

    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
        destinationTaxId
      )
    );
    destinationTaxId &&
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
          gstVatPaid
        )
      );
  };

export const resetExtendedLiability = pageConfig => dispatch => {
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
      false
    )
  );
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
      undefined
    )
  );
};

export const resetAdditionalDataRequirements = pageConfig => dispatch => {
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
      undefined
    )
  );
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
      undefined
    )
  );
};

export const resetInvoiceAdditionalDataRequirements =
  pageConfig => dispatch => {
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.INVOICE.EXPORTER_DETAILS.DESTINATION_TAX_ID_REG_NO,
        undefined
      )
    );
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.INVOICE.EXPORTER_DETAILS.GST_VAT_PAID,
        undefined
      )
    );
  };

export const setupExtendedLiability =
  (allowedLiability, pageConfig) => dispatch =>
    allowedLiability
      ? dispatch(setupDefaultExtendedLiability(pageConfig))
      : dispatch(resetExtendedLiability(pageConfig));

// @see:https://it.dpduk.live/it/diagram/diag_GCJhVN6GAqAAhVnC.html?id=1633609607280
export const setupAdditionalDataRequirements =
  (isVisibleTaxId, pageConfig) => dispatch =>
    isVisibleTaxId
      ? dispatch(setupDefaultAdditionalDataRequirements(pageConfig))
      : dispatch(resetAdditionalDataRequirements(pageConfig));

export const unmountShipmentPage = () => dispatch => {
  dispatch(clearShipment());
  dispatch(AddressBookActions.clearAddressBook());
  dispatch(ReferenceActions.clearReference());
  dispatch(destroy(DELIVERY_DETAILS_SEARCH_FORM));
  dispatch(destroy(RETURN_DETAILS_SEARCH_FORM));
};

export const getShipmentById = createAsyncAction(
  shipmentId =>
    shipmentApi.getShipmentById(shipmentId).then(({ data }) => data),
  ActionTypes.FETCH_BY_ID
);

export const resetReference1 = pageConfig => (dispatch, getState) => {
  const state = getState();
  dispatch(
    initializeForm(pageConfig.formName, {
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]:
        ShipmentModels.getDefaultUniqueShippingRef1(
          get(
            ShipmentSelectors.getShipmentFormValues(state, pageConfig),
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
          ShipmentSelectors.getUniqueSenderRef1(state),
          {
            preferences: UmsSelectors.getPreferences(state),
            shippingSettings: UmsSelectors.getShippingSettings(state),
          }
        ),
    })
  );
};

// manage only manual changing liability, dynamic changing is managed in the handleLiability
// @see https://it.dpduk.live/it/diagram/diag_VLeqsR6C48kIGApK.html?id=1639737191640
export const onLiabilityChange =
  (liability, pageConfig) => (dispatch, getState) => {
    const preferences = UmsSelectors.getPreferences(getState());
    const formValues = ShipmentSelectors.getShipmentFormValues(
      getState(),
      pageConfig
    );
    const liabilityValue = liability
      ? LiabilityModels.getDefaultLiabilityValue(
          preferences,
          getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE,
            ""
          )
        )
      : undefined;
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
        liabilityValue
      )
    );
  };

const onBusinessChangeOld = (pageConfig, value) => (dispatch, getState) => {
  const state = getState();
  const selectedAddressBook = ShipmentSelectors.getSelectedAddressBook(state);

  if (value) {
    dispatch(change(EDIT_IMPORTER_FORM, ImporterDetailsEntity.PID_NUMBER, ""));

    if (!isEmpty(selectedAddressBook?.vatNumber)) {
      dispatch(
        autofill(
          EDIT_IMPORTER_FORM,
          ImporterDetailsEntity.VAT_NUMBER,
          selectedAddressBook.vatNumber
        )
      );
    }
    if (!isEmpty(selectedAddressBook?.eoriNumber)) {
      dispatch(
        autofill(
          EDIT_IMPORTER_FORM,
          ImporterDetailsEntity.EORI_NUMBER,
          selectedAddressBook.eoriNumber
        )
      );
    }
  } else {
    dispatch(change(EDIT_IMPORTER_FORM, ImporterDetailsEntity.EORI_NUMBER, ""));
    dispatch(change(EDIT_IMPORTER_FORM, ImporterDetailsEntity.VAT_NUMBER, ""));

    if (!isEmpty(selectedAddressBook?.pid)) {
      dispatch(
        autofill(
          EDIT_IMPORTER_FORM,
          ImporterDetailsEntity.PID_NUMBER,
          selectedAddressBook.pid
        )
      );
    }
  }
};

const getDefaultFields =
  (pageConfig, isBusiness, atRisk) => (dispatch, getState) => {
    const state = getState();
    const customer = UmsSelectors.getCustomer(state);
    const customerPrefs = UmsSelectors.getCustomerPrefs(state);
    const preferences = UmsSelectors.getPreferences(state);
    const profile = ProfilesSelectors.getActiveProfile(state, pageConfig);
    const selectedService = ReferenceSelectors.getActiveOutboundService(state);
    const userCustomerAddress = UmsSelectors.getCustomerAddress(state);
    const formValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const countryCode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    );
    const postcode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ""
    );
    const selectedCountry = ShipmentSelectors.getSelectedCountry(
      state,
      pageConfig
    );
    const { invoiceDetailsSection, exporterImporterDetails } =
      NewShipmentModels.isShippingInformationVisible(
        customer,
        selectedService,
        {
          generateCustomsData: getValue(
            formValues,
            ShipmentEntity.GENERATE_CUSTOMS_DATA,
            false
          ),
          countryCode,
          postCode: postcode,
          isBusiness,
          atRisk,
        }
      );
    const {
      defaultInvoiceType,
      internationalBillings,
      eoriNumber,
      vatNumber,
      fdaRegistrationNumber,
      destinationTaxId,
      gstVatPaid,
    } = InvoiceModels.getShipmentInfoDefault({
      selectedCountry,
      customerPrefs,
      shipment: formValues,
      selectedService,
      profile,
      preferences,
    });
    const visibleExporterFields =
      ServiceModels.isVisibleExporterTaxRequiredFields(
        selectedService,
        destinationTaxId,
        customer.shippingVersion
      );
    const defaultExporterAddress = AddressModels.getDefaultExporterAddress({
      customer,
      userCustomerAddress,
      profile,
      prefsInvoiceSettings: preferences.prefsInvoiceSettings,
    });
    const defaultExporterDetails = {
      [ADDRESS]: {
        ...pick(defaultExporterAddress, [
          Fields.STREET,
          Fields.LOCALITY,
          Fields.TOWN,
          Fields.COUNTY,
          Fields.POSTCODE,
          Fields.ORGANISATION,
          Fields.COUNTRY_CODE,
        ]),
      },
      [CONTACT_DETAILS]: {
        ...pick(defaultExporterAddress, [
          Fields.CONTACT_NAME,
          Fields.TELEPHONE,
        ]),
      },
      [ExporterDetailsEntity.EORI_NUMBER]: eoriNumber,
      [ExporterDetailsEntity.VAT_NUMBER]: vatNumber,
      ...(visibleExporterFields.fdaRegistrationNumber && {
        [ExporterDetailsEntity.FDA_NUMBER]: fdaRegistrationNumber,
      }),
      ...(visibleExporterFields.destinationTaxId && {
        [ExporterDetailsEntity.DESTINATION_TAX_ID_REG_NO]: destinationTaxId,
      }),
      ...(visibleExporterFields.gstVatPaid && {
        [ExporterDetailsEntity.GST_VAT_PAID]: gstVatPaid,
      }),
    };
    const updatedInvoice = cloneDeep(getValue(formValues, INVOICE));
    const resetFields = [];

    if (invoiceDetailsSection) {
      if (!getValue(updatedInvoice, InvoiceEntity.INVOICE_TYPE)) {
        updatedInvoice[InvoiceEntity.INVOICE_TYPE] = defaultInvoiceType;
      }

      if (
        !getValue(updatedInvoice, InvoiceEntity.INTERNATIONAL_BILLING_TERMS)
      ) {
        updatedInvoice[InvoiceEntity.INTERNATIONAL_BILLING_TERMS] =
          InvoiceModels.getDefaultInternationalBilling(internationalBillings);
      }

      if (!getValue(updatedInvoice, InvoiceEntity.SHIPPING_COST)) {
        updatedInvoice[InvoiceEntity.SHIPPING_COST] = DEFAULT_ZERO_VALUE;
      }
    } else {
      resetFields.push(
        ShipmentEntity.INVOICE.INVOICE_TYPE,
        ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS,
        ShipmentEntity.INVOICE.SHIPPING_COST,
        ShipmentEntity.INVOICE.EXPORT_REASON,
        ShipmentEntity.INVOICE.INVOICE_REFERENCE
      );
    }

    if (exporterImporterDetails) {
      if (
        isEmpty(updatedInvoice.importerDetails?.address) ||
        isEmpty(updatedInvoice.importerDetails?.contactDetails)
      ) {
        updatedInvoice.importerDetails = {
          ...updatedInvoice.importerDetails,
          ...InvoiceModels.addImporterAddressDetails(formValues),
        };
      }

      if (isEmpty(updatedInvoice.exporterDetails?.address)) {
        updatedInvoice.exporterDetails = defaultExporterDetails;
      }
    } else {
      resetFields.push(
        "invoice.importerDetails.address",
        "invoice.importerDetails.contactDetails",
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER,
        "invoice.exporterDetails"
      );
    }

    return {
      fields: { resetFields, updatedInvoice },
      invoiceDetailsSection,
      exporterImporterDetails,
    };
  };

const onBusinessChangeNew =
  (pageConfig, isBusiness, atRisk) => (dispatch, getState) => {
    const state = getState();
    const customer = UmsSelectors.getCustomer(state);
    const selectedAddressBook = ShipmentSelectors.getSelectedAddressBook(state);
    const allowedFields = ShipmentSelectors.getAllowedFields(state, pageConfig);
    const formValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const products = getValue(
      formValues,
      "outboundConsignment.parcels[0].products",
      []
    );
    const parcelId = getValue(
      formValues,
      "outboundConsignment.parcels[0].parcelId",
      ""
    );
    const isNiShipment = AddressModels.isNiShipment(
      getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      ),
      getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        ""
      )
    );
    const { ukimsNumber: defaultExporterUkims } =
      ShipmentModels.getInitialTaxValues(
        ProfilesSelectors.getActiveProfile(state, pageConfig),
        UmsSelectors.getPreferences(state)
      );
    const {
      fields: { resetFields, updatedInvoice },
      exporterImporterDetails,
    } = dispatch(getDefaultFields(pageConfig, isBusiness, atRisk));

    if (isBusiness) {
      if (isNiShipment) {
        updatedInvoice.importerDetails.atRisk = atRisk;
      }

      if (exporterImporterDetails) {
        resetFields.push(ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER);

        if (!isEmpty(selectedAddressBook?.vatNumber)) {
          updatedInvoice.importerDetails.vatNumber =
            selectedAddressBook.vatNumber;
        }

        if (!isEmpty(selectedAddressBook?.eoriNumber)) {
          updatedInvoice.importerDetails.eoriNumber =
            selectedAddressBook.eoriNumber;
        }

        if (
          InvoiceModels.isVisibleUkims(
            {
              isBusiness,
              atRisk,
              countryCode: getValue(
                formValues,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                  .COUNTRY_CODE
              ),
              postcode: getValue(
                formValues,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                  .POSTCODE
              ),
            },
            customer.shippingVersion
          )
        ) {
          updatedInvoice.exporterDetails.ukimsNumber = defaultExporterUkims;

          if (!isEmpty(selectedAddressBook?.ukimsNumber)) {
            updatedInvoice.importerDetails.ukimsNumber =
              selectedAddressBook.ukimsNumber;
          }
        }
      }
    } else {
      if (isNiShipment) {
        updatedInvoice.importerDetails.atRisk = false;
      }

      if (exporterImporterDetails) {
        resetFields.push(
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER,
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER
        );

        if (!isEmpty(selectedAddressBook?.pid)) {
          updatedInvoice.importerDetails.pidNumber = selectedAddressBook.pid;
        }
      }
    }

    if (
      allowedFields[ProductDescriptionsEntity.PRODUCT_DESCRIPTION] &&
      products.length
    ) {
      dispatch(
        arraySplice(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS,
          0,
          1,
          {
            products: products.map(({ productDescription }) => ({
              productDescription,
            })),
            ...(parcelId && { parcelId }),
          }
        )
      );
    }

    dispatch(
      initializeForm(pageConfig.formName, {
        [INVOICE]: toUppercaseValues(updatedInvoice),
      })
    );

    if (resetFields.length) {
      dispatch(resetSection(pageConfig.formName, ...resetFields));
    }
  };

export const onImporterTypeChange =
  (pageConfig, isBusiness, atRisk) => (dispatch, getState) => {
    const customer = UmsSelectors.getCustomer(getState());
    SettingsModels.isNewVersion(customer.shippingVersion)
      ? dispatch(onBusinessChangeNew(pageConfig, isBusiness, atRisk))
      : dispatch(onBusinessChangeOld(pageConfig, isBusiness));
  };

export const onAtRiskChange = (pageConfig, atRisk) => (dispatch, getState) => {
  const state = getState();
  const customer = UmsSelectors.getCustomer(state);
  const selectedAddressBook = ShipmentSelectors.getSelectedAddressBook(state);
  const { ukimsNumber: defaultExporterUkims } =
    ShipmentModels.getInitialTaxValues(
      ProfilesSelectors.getActiveProfile(state, pageConfig),
      UmsSelectors.getPreferences(state)
    );
  const formValues = ShipmentSelectors.getShipmentFormValues(state, pageConfig);
  const isBusiness = getValue(
    formValues,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
    false
  );
  const {
    fields: { resetFields, updatedInvoice },
    exporterImporterDetails,
  } = dispatch(getDefaultFields(pageConfig, isBusiness, atRisk));

  if (atRisk) {
    if (exporterImporterDetails) {
      resetFields.push(
        ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER
      );
    }
  } else {
    if (
      exporterImporterDetails &&
      InvoiceModels.isVisibleUkims(
        {
          isBusiness,
          atRisk,
          countryCode: getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
          postcode: getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .POSTCODE
          ),
        },
        customer.shippingVersion
      )
    ) {
      updatedInvoice.exporterDetails.ukimsNumber = defaultExporterUkims;
      updatedInvoice.importerDetails.ukimsNumber = getValue(
        selectedAddressBook,
        Fields.UKIMS_NUMBER,
        ""
      );
    }
  }

  dispatch(
    initializeForm(pageConfig.formName, {
      [INVOICE]: toUppercaseValues(updatedInvoice),
    })
  );

  if (resetFields.length) {
    dispatch(resetSection(pageConfig.formName, ...resetFields));
  }
};

export const setProductDescription = pageConfig => (dispatch, getState) => {
  const state = getState();

  const currentProductDescription = getProductDescription(
    PRODUCT_DESCRIPTIONS_FORM
  )(state);

  if (isEmpty(currentProductDescription)) {
    return;
  }

  dispatch(
    arrayPush(
      pageConfig.formName,
      `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}[0].products`,
      currentProductDescription
    )
  );
  dispatch(
    change(
      PRODUCT_DESCRIPTIONS_FORM,
      ProductDescriptionsEntity.PRODUCT_DESCRIPTION,
      ""
    )
  );
  dispatch(
    untouch(
      PRODUCT_DESCRIPTIONS_FORM,
      ProductDescriptionsEntity.PRODUCT_DESCRIPTION
    )
  );
};

export const removeProductDescription =
  (pageConfig, productDescriptionIndex) => dispatch => {
    dispatch(
      arrayRemove(
        pageConfig.formName,
        `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}[0].products`,
        productDescriptionIndex
      )
    );
    dispatch(
      untouch(
        PRODUCT_DESCRIPTIONS_FORM,
        ProductDescriptionsEntity.PRODUCT_DESCRIPTION
      )
    );
  };
