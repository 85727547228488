import { useMemo } from "react";

import { PropTypes } from "prop-types";

import { propTypes } from "redux-form";

import * as S from "~/constants/strings";
import { InvoiceModels } from "~/models";
import { formatMessage } from "~/utils/string";

import { getValue } from "~/utils/object";
import NewInvoiceDetails from "~/components/InvoiceDetails/NewInvoiceDetails";
import OldInvoiceDetails from "~/components/InvoiceDetails/OldInvoiceDetails";

const InvoiceDetails = ({
  createShipmentValues,
  selectedService,
  customerPrefs,
  exportReasons,
  selectedCountry,
  disabledFields,
  preferences,
  profile,
  isNewVersion,
  requiredFields,
  onFieldEntry,
}) => {
  const shippingCostLabel = useMemo(
    () =>
      formatMessage(
        S.SHIPPING_COST_$,
        getValue(createShipmentValues, "outboundConsignment.currency", S.GBP)
      ),
    [getValue(createShipmentValues, "outboundConsignment.currency", S.GBP)]
  );

  const shippingInfoDefaults = useMemo(
    () =>
      InvoiceModels.getShipmentInfoDefault({
        customerPrefs,
        exportReasons,
        selectedCountry,
        selectedService,
        preferences,
        shipment: createShipmentValues,
        profile,
      }),
    [
      customerPrefs,
      exportReasons,
      selectedCountry,
      selectedService,
      preferences,
      createShipmentValues,
      profile,
    ]
  );

  return isNewVersion ? (
    <NewInvoiceDetails
      shippingCostLabel={shippingCostLabel}
      shippingInfoDefaults={shippingInfoDefaults}
      disabledFields={disabledFields}
      requiredFields={requiredFields}
      onFieldEntry={onFieldEntry}
    />
  ) : (
    <OldInvoiceDetails
      shippingCostLabel={shippingCostLabel}
      shippingInfoDefaults={shippingInfoDefaults}
      disabledFields={disabledFields}
      requiredFields={requiredFields}
      onFieldEntry={onFieldEntry}
    />
  );
};

InvoiceDetails.propTypes = {
  ...propTypes,
  customerPrefs: PropTypes.object,
  requiredFields: PropTypes.object,
  disabledFields: PropTypes.object,
  isNewVersion: PropTypes.bool,
  onFieldEntry: PropTypes.func,
};
InvoiceDetails.defaultProps = {
  isNewVersion: false,
};

export default InvoiceDetails;
